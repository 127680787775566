<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <div>
            <v-col md="12" class="mr-btm d-flex justify-space-between">
              <div>
                <router-link to="/dashboard/whitelabel/add" class="leftSec addButton" v-if="!importLoading"
                  ><v-btn>Add White Label</v-btn></router-link
                >
                <v-btn
                  class="leftSec addButton"
                  v-if="importLoading"
                  loading
                ></v-btn>
              </div>
            </v-col>
          </div>
        </v-col>

        <v-col md="12" v-if="whitelabelLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-if="!whitelabelLoading">
          <v-simple-table class="tableborder">
            <thead>
              <tr>
                <th>S/N</th>
                <th width="200">Name</th>
                <th class="relRow">AppId</th>
                <th class="relRow">AppSecret</th>
                <!-- <th class="relRow" width="200">Logo</th>
                <th>Color 1</th>
                <th>Color 2</th> -->
                 <th>Calculator</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              <tr v-if="whitelabelLoading.length == 0">
                <td colspan="14">No Property Found</td>
              </tr>
              <tr v-for="(data, index) in whitelabels" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ data.name }}</td>
                <td>{{ data.appId }}</td>
                <td>{{ data.appSecret }}</td>
                <!-- <td><div :style="{background:data.color1,padding:'10px'}"><img  :src="data.logo " width="100"/></div></td>
                <td><div :style="{background:data.color1,padding:'10px'}"></div></td>
                <td><div :style="{background:data.color2,padding:'10px'}"></div></td> -->
                <td><v-icon @click="openModal(data.appId)"> mdi-calculator </v-icon></td>
                <td>
                  <div class="d-flex flex-row align-center">
                    <router-link
                      :to="`/dashboard/whitelabel/add/${data._id}`"
                      target="_blank"
                      class="mr-3"
                    >
                      <v-icon class="editIcon ml-2"> mdi-eye </v-icon>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Calculator Code
        </v-card-title>

        <v-card-text class="mt-5">
      <code> &lt;iframe :src="`http://betaapi.truvals.com/api/v1/whitelabel/calculator?appId={{appId}}" width="100%" height="100%" /&gt;</code>
       
         
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=""
            text
            @click="dialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

export default {
  name: "WhiteLabels",

  components: {
    Loading,
  },

  data() {
    return {
      snackbar: false,
      text: "",
      whitelabels: [],
      importLoading: false,
      whitelabelLoading: true,
      loadingDelete: false,
      appId: "",
      dialog:false
    };
  },

  methods: {
    ...mapActions(["getWhiteLabels"]),
    openModal(data){
      this.dialog = true;
      this.appId = data;
    }
  },
  computed: {
    ...mapGetters(["allWhiteLabels"]),
  },
  async created() {
    await this.getWhiteLabels();
    this.whitelabels = this.allWhiteLabels;
    this.whitelabelLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dateHighlight {
  background: yellow;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 0;
}
.v-icon {
  font-size: 16px;
  cursor: pointer;
}
.downloadbtn {
  margin-left: 20px;
}
.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
.heightZero {
  height: 0;
}
.actionbtn {
  margin-bottom: 20px;
}
.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);
  a {
    color: rgb(5, 5, 5);
  }
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}
a {
  text-decoration: none;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;
  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}
.relRow {
  position: relative;
  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }
  &:hover {
    .dropBox {
      display: block;
    }
  }
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 500px;
    height: 500px;
  }
}
.flexBox-space {
  display: flex;
  justify-content: space-between;
}
.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
.flexend {
  justify-content: flex-end;
  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;
  h5 {
    font-size: 16px;
    margin-bottom: 5px;
    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }
  margin-bottom: 20px;
  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;
    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;
      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}
.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}
.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;
  &:nth-of-type(1) {
    left: 280px;
  }
  &:nth-of-type(2) {
    left: 420px;
  }
}
.favourite {
  color: red;
  width: 35px;
  height: 35px;
}
.favouriteO {
  width: 35px;
  height: 35px;
}
.addButton {
  // margin-left: 310px;
  min-width: 200px !important;
}
</style>
